import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { PalleteList } from '../../../components/PalleteList';
import { TabLinks } from '../../../components/TabLinks';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "colors",
      "style": {
        "position": "relative"
      }
    }}>{`Colors`}</h1>
    <TabLinks items={[{
      title: 'Pallete',
      href: '/design-guidelines/color'
    }, {
      title: 'Usage',
      href: '/design-guidelines/color/usage'
    }]} mdxType="TabLinks" />
    <p>{`In Bold, colors are used to express states of interactive components, status, and emphasize visual and textual content. All palettes were built according to accessibility standards, fulfilling the minimum contrast of interactive elements and text with the background. The palettes were also designed thematically, and it maintains the optimum contrast of all components, as well as works in reverse, in dark mode.`}</p>
    <PalleteList mdxType="PalleteList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      